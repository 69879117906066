<template>
  <div class="admin-feed-block">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['complaints']" />
    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="statistics-block-content">
        <AdminComplaintsCard
          v-for="(complaint, index) in complaints"
          :key="index"
          :complaint="complaint"
          :card="{ desc: periodLabel, numLabel: date_locale(complaint.date) }"
        />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminComplaintsCard: () => import("./AdminComplaintsCard.vue"),
    LastPeriodFilter: () => import("../widgets/LastPeriodFilter.vue"),
  },
  data: function() {
    return {
      loading: true,
    };
  },
  methods: {
    openFilter: function() {
      this.$refs.context.open();
    },
    setPeriod: function(data) {
      const { period, startDate, endDate } = typeof data === "string" ? { period: data } : data;
      this.setQuery({ period, startDate, endDate });
    },
    getQuery: function({ page }) {
      const { period, startDate, endDate } = this.$route.query;
      let query = `?period=${period}&page=${page < 1 ? 1 : page}`;
      query += `&startDate=${startDate}&endDate=${endDate}`;
      return query;
    },
    getStatistics: async function({ page }) {
      this.isLoading(true);
      try {
        const query = this.getQuery({ page });
        const response = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/complaints-home${query}`);
        this.$store.commit("setAdminComplaints", { ...response.data });
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    getPercent: function(mount, total) {
      return Math.round((mount / total) * 100);
    },
    getTimeConnected: function(time) {
      let [hours, minutes, seconds, result] = [0, 0, 0];
      hours = Math.floor(time / 3600000);
      minutes = Math.floor((time - hours * 3600000) / 60000);
      seconds = Math.floor((time - hours * 3600000 - minutes * 60000) / 1000);
      result = `${hours} h ${minutes} m`;

      if (!hours) {
        result = `${minutes} m`;
      }

      if (!hours && !minutes) {
        result = `${seconds} s`;
      }

      return result;
    },
  },
  mounted: function() {
    this.isLoading(true);
    setTimeout(() => {
      this.getStatistics({ page: 1 });
    }, 1000);
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
    period: function() {
      return this.$route.query.period || "last-6-months";
    },
    periodLabel: function() {
      const fromDate = this.$global.dateLocaleFormat(this.statistics.startDate);
      const toDate = this.$global.dateLocaleFormat(this.statistics.endDate);
      return `${fromDate}, ${toDate}`;
    },
    complaints: function() {
      return this.$store.getters.complaints.complaints;
    },
    statistics: function() {
      return this.$store.getters.complaints.statistics;
    },
  },
  watch: {
    "$route.query": function() {
      this.getStatistics({ page: 1 });
    },
  },
};
</script>
